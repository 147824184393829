import axios from '../plugins/axios'

// endpoints
import auth from './auth'
import user from './user'
import role from './role'
import shop from './shop'
import city from './city'
import region from './region'
import event from './event'
import eventType from './eventType'
import eventHistory from './eventHistory'
import centrifuge from './centrifuge'

const api = {
  axios, // set globally as helper if need custom requests avoiding this.$api
  auth,
  user,
  role,
  shop,
  city,
  event,
  eventType,
  eventHistory,
  centrifuge,
  region,
}

export default api
