<template>
  <nav class="sidebar d-none d-md-flex">
    <div class="sidebar-sticky l-fullscreen pt-0">
      <ul class="nav flex-column">
        <router-link to="/score" v-if="$can(['read-eventHistory'])"
                     class="nav-link">
          <li class="nav-item">
            <i class="nav-icon icons8-stats"></i>
            <span>Статистика</span>
          </li>
        </router-link>
        <router-link :to="'/users/' + $store.getters['auth/userId']" v-if="$can(['read-user']) && isUser"
                     class="nav-link">
          <li class="nav-item">
            <i class="nav-icon icons8-male-user"></i>
            <span>Профиль</span>
          </li>
        </router-link>
        <router-link to="/users" v-if="$can(['read-user']) && !isUser"
                     class="nav-link">
          <li class="nav-item">
            <i class="nav-icon icons8-find-user-male"></i>
            <span>Пользователи</span>
          </li>
        </router-link>
        <router-link to="/shops" v-if="$can(['read-shop']) && !isUser"
                     class="nav-link">
          <li class="nav-item">
            <i class="nav-icon icons8-market"></i>
            <span>Магазины</span>
          </li>
        </router-link>
        <router-link to="/events" v-if="$can(['read-event'])"
                     class="nav-link">
          <li class="nav-item">
            <i class="nav-icon icons8-event"></i>
            <span>Мероприятия</span>
          </li>
        </router-link>
        <router-link to="/cities" v-if="$can(['read-city'])"
                     class="nav-link">
          <li class="nav-item">
            <i class="nav-icon icons8-city-landscape"></i>
            <span>Города</span>
          </li>
        </router-link>
        <router-link to="/qr" v-if="$can(['create-eventHistory'])"
                     class="nav-link">
          <li class="nav-item">
            <i class="nav-icon icons8-qr-code"></i>
            <span>Регистрация QR</span>
          </li>
        </router-link>
      </ul>
    </div>
  </nav>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'SideBar',

    computed: {
      ...mapGetters( {
        isUser: 'auth/isUser',
      } ),
    },
  }
</script>

<style lang="scss" scoped>
  .router-link-exact-active,
  .router-link-active {
    color: theme-color('primary') !important;
  }

  .sidebar {
    padding: 20px 15px;
    width: 270px;
  }

  .nav {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }

  .nav-link {
    color: $gray-700;
    padding: 1rem 1rem
  }

  .nav-icon {
    margin-right: 20px;
    font-size: $h5-font-size;
  }

  .router-link-exact-active,
  .router-link-active {
    color: $primary;
  }

  .sidebar {
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }

  .sidebar-sticky {
    position: sticky;
    top: 60px;
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>
