import axios from '../plugins/axios'

const user = {
  createUser( formData ) {
    return axios.post( '/users', formData )
  },

  updateUser( id, formData, include = null ) {
    if ( include ) {
      return axios.patch( `/users/${id}?include=${include}`, formData )
    }

    return axios.patch( `/users/${id}`, formData )
  },

  findAllUsers( options = {} ) {
    if ( !options.paginate && options.params || options.paginate && !options.params ) {
      console.error( 'invalid paginate options: paginate must be == 1 and vue-table-2 params passed' )
    }

    return axios.get( `/users`, {
      params: {
        include: options.include || null,
        paginate: options.paginate || null,
        ascending: options.params ? options.params.ascending : null,
        byColumn: options.params ? options.params.byColumn : null,
        orderBy: options.params ? options.params.orderBy : null,
        limit: options.params ? options.params.limit : null,
        page: options.params ? options.params.page : null,
        query: options.params && options.params.query ? options.params.query : null,
      },
    } )
  },

  findAllTrashedUsers( options = {} ) {
    if ( !options.paginate && options.params || options.paginate && !options.params ) {
      console.error( 'invalid paginate options: paginate must be == 1 and vue-table-2 params passed' )
    }

    return axios.get( `/users/trashed`, {
      params: {
        include: options.include || null,
        paginate: options.paginate || null,
        ascending: options.params ? options.params.ascending : null,
        byColumn: options.params ? options.params.byColumn : null,
        orderBy: options.params ? options.params.orderBy : null,
        limit: options.params ? options.params.limit : null,
        page: options.params ? options.params.page : null,
        query: options.params && options.params.query ? options.params.query : null,
      },
    } )
  },

  findUserById( id, include = null ) {
    if ( include ) {
      return axios.get( `/users/${id}`, { params: { include } } )
    }

    return axios.get( `/users/${id}` )
  },

  generatePassword() {
    return axios.get( '/users/password' )
  },

  deleteUser( id ) {
    return axios.delete( `/users/${id}` )
  },

  restoreUser( id ) {
    return axios.put( `/users/restore`, { id: id } )
  },
}

export default user
