<template>
  <div v-if="!registrationQrError && Object.keys( selectedEvent ).length > 0">
    <div class="score-top-panel d-flex flex-nowrap shadow">
      <div @click="closeRegistrationQR" class="score-top-panel__btn-back">
        <i class="icons8-back-arrow"></i>
      </div>
      <div class="score-top-panel__info">
        <span>{{ selectedEvent.name | shortness(20) }}</span>
        <div class="d-flex align-items-center">
          <span>Сегодня, {{ opensFromTime }}</span>
        </div>
      </div>
    </div>

    <div v-if="Object.keys( selectedEvent ).length > 0" class="score-camera">
      <div class="d-flex flex-wrap align-items-center justify-content-center">
        <qrcode-stream :camera="facingMode" @init="onInit" @decode="onDecode">
          <button @click="switchCamera"
                  class="btn btn-outline-secondary score__btn-switch-camera">
            <i class="icons8-refresh" title="Сменить камеру"></i>
          </button>
        </qrcode-stream>
      </div>
    </div>
  </div>
</template>

<script>
  import { captureMessage as SentryMessage } from '@sentry/vue'
  import isEmpty from 'lodash/isEmpty'

  const REAR_CAMERA = "rear";
  const FRONT_CAMERA = "front";

  export default {
    name: 'QrScoringPoints',

    metaInfo() {
      return {
        title: 'Сканирование QR-кодов',
      }
    },

    data() {
      return {
        facingMode: REAR_CAMERA,
        noFrontCamera: false,
      }
    },

    computed: {
      selectedEvent() {
        return this.$store.state.registrationQR.selectedEvent
      },

      opensFromTime() {
        return this.selectedEvent.opens_from.split( ' ' )[1].slice( 0, 5 )
      },

      registrationQrError() {
        return this.$store.state.registrationQR.error
      },
    },

    methods: {
      onDecode( decodedString ) {
        let string = decodedString.split( '&' )

        let formData = {
          uid: string[0].slice( 4 ),
          uem: !isEmpty( string[1] ) ? string[1].slice( 4 ) : null,
          eid: this.selectedEvent.id,
        }

        this.$api.eventHistory.createEventHistory( formData ).then( res => {
          if ( res.status === 201 ) {
            this.$toasted.show( res.data.message, {
              theme: 'toasted-primary',
              type: 'success',
              position: 'bottom-center',
              duration: 3500,
            } )
          }
        } )
      },

      switchCamera() {
        if ( this.facingMode === FRONT_CAMERA ) {
          this.facingMode = REAR_CAMERA
        } else {
          this.facingMode = FRONT_CAMERA
        }
      },

      async onInit( promise ) {
        try {
          await promise

          // successfully initialized
          this.$store.commit( 'SET_REGISTRATION_QR_ERROR', false )

        } catch ( error ) {
          this.$store.commit( 'SET_REGISTRATION_QR_ERROR', true )

          if ( this.noFrontCamera = this.facingMode === FRONT_CAMERA && error.name === 'OverconstrainedError' ) {
            this.$toasted.show( 'Селфи-камера не найдена.', { type: 'error' } )
          }

          if ( error.name === 'NotAllowedError' ) {
            this.$toasted.show( 'Разрешите доступ к камере.', { type: 'error' } )

          } else if ( error.name === 'NotFoundError' ) {
            this.$toasted.show( 'Камера не найдена.', { type: 'error' } )

          } else if ( error.name === 'NotSupportedError' ) {
            this.$toasted.show( 'Передача видео возможна только по зашифрованному каналу.', { type: 'error' } )

          } else if ( error.name === 'NotReadableError' ) {
            this.$toasted.show( 'Камера недоступна.', { type: 'error' } )

          } else if ( error.name === 'OverconstrainedError' ) {
            // passed constraints don't match any camera.
            // Did you requested the front camera although there is none?
            this.$toasted.show( 'Ошибка определения параметров камеры.', { type: 'error' } )

          } else if ( error.name === 'StreamApiNotSupportedError' ) {
            // browser seems to be lacking features
            this.$toasted.show( 'Ваше устройство не поддерживается.', { type: 'error' } )

          } else {
            this.$toasted.show( 'Ошибка камеры. ', { type: 'error' } )
          }

          SentryMessage( `constraint: ${error.constraint}, message: ${error.message},
            stack: ${error.stack}, name: ${error.name}` )
        } finally {
          // hide loading indicator
        }
      },

      closeRegistrationQR() {
        this.$store.commit( 'SET_REGISTRATION_QR_OPENED', false )
      },
    },
  }
</script>

<style lang="scss">
  .score-top-panel {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 60px;
    z-index: $zindex-sticky;
    background-color: $white;
  }

  .score-top-panel__btn-back {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 100px;
    font-size: 2rem;
    cursor: pointer;
  }

  .score-top-panel__info {
    padding: 8px;
    width: 100%;
  }

  .score-top-panel__total {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    width: 100px;
  }

  .score-camera {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-dropdown;
  }

  .score__btn-switch-camera {
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: $zindex-fixed;

    &:focus {
      outline: none !important;
    }
  }
</style>
