import axios from '../plugins/axios'

const region = {
  findRegionByName( query ) {
    return axios.get( `/regions/${query}` )
  },

  findAllRegions( options = {} ) {
    if ( !options.paginate && options.params || options.paginate && !options.params ) {
      console.error( 'invalid paginate options: paginate must be == 1 and vue-table-2 params passed' )
    }

    return axios.get( `/regions`, {
      params: {
        include: options.include || null,
        paginate: options.paginate || null,
        ascending: options.params ? options.params.ascending : null,
        byColumn: options.params ? options.params.byColumn : null,
        orderBy: options.params ? options.params.orderBy : null,
        limit: options.params ? options.params.limit : null,
        page: options.params ? options.params.page : null,
        query: options.params && options.params.query ? options.params.query : null,
      },
    } )
  },
}

export default region
