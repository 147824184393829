<template>
  <nav class="navbar sticky-top d-lg-flex d-none">
    <div class="col">
      <img src="@/assets/logo.png" alt="FinalScore" class="navbar-image mh-100">
    </div>
    <div class="col px-0 user-account d-flex align-items-center justify-content-end">
      <div class="dropdown">
        <button type="button" class="btn btn-link">
          {{ userName }}
          <i class="icons8-drop-down-arrow"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right m-0">
          <router-link v-if="$can(['read-user'])" :to="'/users/' + $store.getters['auth/userId']"
                       class="dropdown-item">
            Мой профиль
          </router-link>
          <a @click.prevent="logout" href="#" class="dropdown-item">
            Выйти
          </a>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
  export default {
    name: 'NavBar',

    computed: {
      userName() {
        if ( this.$store.getters['auth/user'] ) {
          return this.$store.getters['auth/user'].name
        }
      },
    },

    methods: {
      logout() {
        this.$store.dispatch( 'auth/logoutUser' ).then( res => {
          if ( res.status === 204 ) {
            this.$centrifuge.disconnect()
            this.$router.push( '/login' )
          }
        } )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .dropdown:hover .dropdown-menu {
    display: block;
  }

  .dropdown-item {
    padding: 1rem 1.5rem;
  }

  .navbar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    box-shadow: 0 0 15px rgba(0, 0, 0, .2);
    background-color: rgba(255, 255, 255, .7);
  }

  .navbar-image {
    max-width: 150px;
  }
</style>
