<template>
  <div class="c-mobile-side-bar">

    <button @click="menuModalShow = !menuModalShow"
            class="hamburger hamburger--squeeze fixed-bottom"
            :class="{ 'is-active': menuModalShow }">
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>

    <transition name="fade">
      <div v-show="menuModalShow" class="c-mobile-side-bar__menu" @click="menuModalShow = false">
        <ul class="c-mobile-side-bar__list list-group ">
          <li class="list-group-item d-flex align-items-center justify-content-between">
            <router-link :to="'/users/' + $store.getters['auth/userId']">
              <i class="icons8-left-arrow"></i>
              {{ $store.getters['auth/user'].name }}
            </router-link>
            <button @click="logout"
                    class="btn btn-outline-secondary btn-sm float-right">
              Выход
            </button>
          </li>
          <router-link to="/score" v-if="$can(['read-eventHistory']) && !isUser">
            <li class="list-group-item">
              <i class="icons8-stats"></i>
              <span>Статистика</span>
            </li>
          </router-link>
          <router-link to="/users" v-if="$can(['read-user']) && !isUser">
            <li class="list-group-item">
              <i class="icons8-find-user-male"></i>
              <span>Пользователи</span>
            </li>
          </router-link>
          <router-link to="/shops" v-if="$can(['read-user']) && !isUser">
            <li class="list-group-item">
              <i class="icons8-market"></i>
              <span>Магазины</span>
            </li>
          </router-link>
          <router-link to="/events" v-if="$can(['read-event']) && !isUser">
            <li class="list-group-item">
              <i class="icons8-event"></i>
              <span>Мероприятия</span>
            </li>
          </router-link>
          <router-link to="/cities" v-if="$can(['read-city']) && !isUser">
            <li class="list-group-item">
              <i class="icons8-city-landscape"></i>
              <span>Города</span>
            </li>
          </router-link>
          <router-link to="/qr" v-if="$can(['create-eventHistory']) && !isUser">
            <li class="list-group-item">
              <i class="icons8-qr-code"></i>
              <span>Регистрация QR</span>
            </li>
          </router-link>
        </ul>
      </div>
    </transition>

  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'MobileSideBar',

    data() {
      return {
        menuModalShow: false,
      }
    },

    computed: {
      ...mapGetters( {
        isUser: 'auth/isUser',
      } ),
    },

    watch: {
      menuModalShow() {
        document.body.style.overflow = this.menuModalShow ? 'hidden' : 'auto'
      },
    },

    methods: {
      logout() {
        this.$store.dispatch( 'auth/logoutUser' ).then( res => {
          if ( res.status === 204 ) {
            this.$router.push( '/login' )
          }
        } )
      },
    },
  }
</script>

<style lang="scss" scoped>
  .router-link-exact-active,
  .router-link-active {
    color: theme-color('primary') !important;
  }

  .c-mobile-side-bar {
    .hamburger {
      background-color: rgba(255, 255, 255, .7);
      box-shadow: 0 0 15px rgba(0, 0, 0, .15);
      border-top-right-radius: 1rem;
      width: 70px;
    }
  }

  .c-mobile-side-bar__menu {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.8);
    z-index: $zindex-sticky;
  }

  .c-mobile-side-bar__list {
    position: absolute;
    bottom: 0;
    right: 0;
    box-shadow: 0 0 15px rgba(0, 0, 0, .15);
    width: calc(100% - 80px);

    &.list-group {
      a {
        color: $gray-800;

        span {
          margin-left: 1.25rem;
        }
      }

      .list-group-item {
        width: 100%;
        padding: 15px 20px;
      }
    }
  }
</style>
