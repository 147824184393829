<template>
  <div class="container-fluid px-0">
    <slot/>
  </div>
</template>

<script>
  export default {
    name: 'LayoutBlank',
  }
</script>
