import axios from '../plugins/axios'

const event = {
  createEvent( formData ) {
    return axios.post( '/events', formData )
  },

  updateEvent( id, formData, include = null ) {
    if ( include ) {
      return axios.patch( `/events/${id}?include=${include}`, formData )
    }

    return axios.patch( `/events/${id}`, formData )
  },

  findAllEvents( options = {} ) {
    if ( !options.paginate && options.params || options.paginate && !options.params ) {
      console.error( 'invalid paginate options: paginate must be == 1 and vue-table-2 params passed' )
    }

    return axios.get( `/events`, {
      params: {
        include: options.include || null,
        paginate: options.paginate || null,
        ascending: options.params ? options.params.ascending : null,
        byColumn: options.params ? options.params.byColumn : null,
        orderBy: options.params ? options.params.orderBy : null,
        limit: options.params ? options.params.limit : null,
        page: options.params ? options.params.page : null,
        query: options.params && options.params.query ? options.params.query : null,
      },
    } )
  },

  findEventById( id, include = null ) {
    if ( include ) {
      return axios.get( `/events/${id}`, { params: { include } } )
    }

    return axios.get( `/events/${id}` )
  },

  findEventsForToday() {
    return axios.get( '/events/today?include=event_type' )
  },

  deleteEvent( id ) {
    return axios.delete( `/events/${id}` )
  },

  getEventsScore() {
    return axios.get( '/events/score?include=event_type' )
  },

  getEventScoreById( eventId ) {
    return axios.get( `/events/${eventId}/score?include=event_history.user.shop` )
  },
}

export default event
