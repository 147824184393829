<template>
  <nav class="w-100" aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li v-for="(crumb, i) in crumbs" class="breadcrumb-item">
        <router-link v-if="i < crumbs.length - 1" :to="crumb.path">{{ crumb.name }}</router-link>
        <span v-else>{{ crumb.name }}</span>
      </li>
    </ol>
  </nav>
</template>

<script>
  import last from 'lodash/last'
  import isEmpty from 'lodash/isEmpty'

  export default {
    name: 'Breadcrumbs',

    computed: {
      crumbs() {
        let path = ''

        let crumbs = [{ name: 'Главная', path: '/' }]

        if ( !this.$route ) {
          return []
        }

        const originalCrumbs = this.$route.path.split( '/' )
        const lastMatched = last( this.$route.matched ) || {}
        const routeCrumbs = !isEmpty( lastMatched ) ? lastMatched.meta.crumbs : ''
        const metaCrumbs = routeCrumbs ? lastMatched.meta.crumbs : ''
        const splitMatched = metaCrumbs.split( '/' )

        for ( let i = 1; i < originalCrumbs.length; i++ ) {
          let originalName = originalCrumbs[i]
          let customName = splitMatched[i]

          let name = customName || originalName

          // dynamic params
          if ( customName === '{:}' ) {
            name = originalName
          }

          if ( originalName === '' ) {
            continue
          }

          path += '/' + originalName

          crumbs.push( { name: name, path: path } )
        }

        return crumbs
      },
    },
  }
</script>

<style lang="scss" scoped>
  .breadcrumb {
    margin-bottom: 0 !important;
    background: linear-gradient(to right, rgba(70, 60, 100, 0.1) 0%, rgba(70, 60, 100, 0) 60%) !important;
  }
</style>
