import axios from '../plugins/axios'

const shop = {
  createShop( formData ) {
    return axios.post( '/shops', formData )
  },

  updateShop( id, formData, include = null ) {
    if ( include ) {
      return axios.patch( `/shops/${id}?include=${include}`, formData )
    }

    return axios.patch( `/shops/${id}`, formData )
  },

  findAllShops( options = {} ) {
    if ( !options.paginate && options.params || options.paginate && !options.params ) {
      console.error( 'invalid paginate options: paginate must be == 1 and vue-table-2 params passed' )
    }

    return axios.get( `/shops`, {
      params: {
        include: options.include || null,
        paginate: options.paginate || null,
        ascending: options.params ? options.params.ascending : null,
        byColumn: options.params ? options.params.byColumn : null,
        orderBy: options.params ? options.params.orderBy : null,
        limit: options.params ? options.params.limit : null,
        page: options.params ? options.params.page : null,
        query: options.params && options.params.query ? options.params.query : null,
      },
    } )
  },

  findShopById( id, include = null ) {
    if ( include ) {
      return axios.get( `/shops/${id}`, { params: { include } } )
    }

    return axios.get( `/shops/${id}` )
  },

  deleteShop( id ) {
    return axios.delete( `/shops/${id}` )
  },
}

export default shop
