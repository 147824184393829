<template>
  <div v-if="isAuth">
    <vue-progress-bar />

    <NavBar />

    <div class="container-fluid">
      <div class="row">

        <keep-alive>
          <component :is="currentSideBar"></component>
        </keep-alive>

        <main role="main" class="c-content col bg-light px-3 pb-3">
          <div class="row">
            <Breadcrumbs v-show="$store.getters.windowSize === 'large' && !isUser" />
          </div>

          <div class="mt-0 mt-lg-3">
            <slot />
          </div>
        </main>

      </div>
    </div>

    <v-dialog />

  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import debounce from 'lodash/debounce'
  import NavBar from '@/components/NavBar'
  import SideBar from '@/components/SideBar'
  import MobileSideBar from '@/components/mobile/MobileSideBar'
  import Breadcrumbs from '@/components/Breadcrumbs'

  export default {
    name: 'LayoutAdmin',

    components: {
      NavBar,
      SideBar,
      MobileSideBar,
      Breadcrumbs,
    },

    data() {
      return {
        currentSideBar: null,
      }
    },

    computed: {
      ...mapGetters( {
        isAuth: 'auth/isAuth',
        isUser: 'auth/isUser',
      } ),
    },

    created() {
      this.getClientWidth()

      window.addEventListener( 'resize', this.getClientWidth )
    },

    mounted() {
      this.$api.centrifuge.token().then( res => {
        if ( res.data && res.data.access_token ) {
          this.$centrifuge.setToken( res.data.access_token )
          this.$centrifuge.connect()
        }
      } )
    },

    destroyed() {
      window.removeEventListener( 'resize', this.getClientWidth )
    },

    methods: {
      getClientWidth: debounce(
        function () {
          this.$store.dispatch( 'setWindowSize' ).then( () => {
            this.currentSideBar = this.$store.getters.windowSize === 'large'
              ? SideBar
              : MobileSideBar
          } )
        }, 100 ),
    },
  }
</script>
