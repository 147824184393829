import axios from '../plugins/axios'

const city = {
  createCity( formData ) {
    return axios.post( '/cities', formData )
  },

  updateCity( id, formData, include = null ) {
    if ( include ) {
      return axios.patch( `/cities/${id}?include=${include}`, formData )
    }

    return axios.patch( `/cities/${id}`, formData )
  },

  findCityByName( query, include = null ) {
    if ( include ) {
      return axios.get( `/cities/${query}?include=${include}` )
    }

    return axios.get( `/cities/${query}` )
  },

  findAllCities( options = {} ) {
    if ( !options.paginate && options.params || options.paginate && !options.params ) {
      console.error( 'invalid paginate options: paginate must be == 1 and vue-table-2 params passed' )
    }

    return axios.get( `/cities`, {
      params: {
        include: options.include || null,
        paginate: options.paginate || null,
        ascending: options.params ? options.params.ascending : null,
        byColumn: options.params ? options.params.byColumn : null,
        orderBy: options.params ? options.params.orderBy : null,
        limit: options.params ? options.params.limit : null,
        page: options.params ? options.params.page : null,
        query: options.params && options.params.query ? options.params.query : null,
      },
    } )
  },

  findCityById( id, include = null ) {
    if ( include ) {
      return axios.get( `/cities/${id}`, { params: { include } } )
    }

    return axios.get( `/cities/${id}` )
  },

  deleteCity( id ) {
    return axios.delete( `/cities/${id}` )
  },
}

export default city
