import axios from '../plugins/axios'

const auth = {
  login( formData ) {
    return axios.post( '/oauth/web/login', formData )
  },

  me() {
    return axios.post( '/oauth/web/me' )
  },

  logout() {
    return axios.post( '/oauth/web/logout' )
  },

  refresh( refreshToken ) {
    return axios.post( '/oauth/web/refresh', { 'refresh_token': refreshToken } )
  },
}

export default auth
